import React from "react";
import ConnectedSettingsData from "./BandwidthTools/ConnectedSettingsData";
import CostTools from "./CostTools";
import LaunchApprovalTools from "./LaunchApproval/LaunchApprovalTools";
import { TextTools } from "./TextTools/TextTools";
import ScriptTools from "./ScriptTools/ScriptTools";

export const SystemAdminTools = ({ 
  selectedCampaignData, 
  userPermissions,
  showCostTools=false, 
  editCostTools=false }) => {

  return (
    <div>
      
      { userPermissions.includes("VIEW_CAMPAIGN_LAUNCH_APPROVAL_TOOLS") && (
        <>
          <LaunchApprovalTools
            active={selectedCampaignData.active}
            selectedCampaignData={selectedCampaignData}
          />
          <hr />
        </>
      )}

      <TextTools
        campaignid={selectedCampaignData.campaignid}
        recipientbatchsize={selectedCampaignData.recipientbatchsize}
        actionablethreshold={selectedCampaignData.actionablethreshold}
      />

      <ScriptTools
        partialScriptID={selectedCampaignData.partial_complete_script_id}
        partialAnswerList={selectedCampaignData.partial_complete_answer_list}
        script={selectedCampaignData.script}
      />
      <hr />

      { userPermissions.includes("VIEW_BANDWIDTH_REGISTRATION_TOOLS") && (
        <>
          <hr />
          <ConnectedSettingsData
            campaignid={selectedCampaignData.campaignid}
            pnpercopilot={selectedCampaignData.pnpercopilot}
            bw_subaccount_id={selectedCampaignData.bw_subaccount_id}
            bw_location_id={selectedCampaignData.bw_location_id}
            bw_registration_id={selectedCampaignData.bw_registration_id}
            has_unassigned_recipients={selectedCampaignData.has_unassigned_recipients}
            has_unregistered_outgoing_lines={selectedCampaignData.has_unregistered_outgoing_lines}
          />
        </>
      )}
      <hr />
      {showCostTools && (
        <CostTools
          edit={editCostTools}
          smssurcharge={selectedCampaignData.smssurcharge}
          campaigncosts={selectedCampaignData.campaigncosts}
          spendlimit={selectedCampaignData.spendlimit}
          totalagenthours={selectedCampaignData.totalagenthours}
          costperagenthour={selectedCampaignData.costperagenthour}
          campaignid={selectedCampaignData.campaignid}
        />
      )}
    </div>
  );
};
