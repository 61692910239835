import React from "react";

import RenameModal from "./modals/RenameModal";
import CopyCampaignModal from "./modals/CopyCampaignModal";
import ConfirmActionModal from "./modals/ConfirmActionModal";
import PauseResumeCampaignModal from "./modals/PauseResumeCampaignModal";
import CampaignResultsModal from "./CampaignResults/CampaignResultsModal";
import { Checkbox, WarningCheckbox } from "./generic/Checklist/Checkbox";
import { campaignReadyStates } from "../util/helper";
export const CampaignTools = ({
  name,
  campaignid,
  campaigns,
  active,
  history,
  jwt,
  vitals,
  exports: fileExports,

  requestCampaignLaunch,
  deleteCampaign,
  deactivateCampaign,
  enterSandboxMode,
  exitSandboxMode,

  startdate,
  enddate,
  subtwiliosid,
  script,
  prompt,
  login,
  listlength,
}) => {
  
  // Set campaignReady values
  let campaignReady = campaignReadyStates({ script, prompt, login, listlength });
  let campaignRunning = (active === "active" || active === "sandbox" || active === "deactivated");

  // Set the status message
  let statusElement = null;
  const dateFormatOpts = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  switch (active) {
    case "notyet":
      statusElement = <span>Not Yet Launched</span>; break;
    case "sandbox":
      statusElement = <span className="has-text-primary">Sandbox Mode</span>; break;
    case "prelaunch":
      statusElement = <span className="has-text-success">Launch Approval Pending</span>; break;
    case "active":
      const startDateStr = new Date(startdate).toLocaleString('en-US', dateFormatOpts);
      statusElement = <><span className="has-text-success">Launched </span>on {startDateStr} </>; break;
    case "paused":
      statusElement = <span className="has-text-danger">Paused</span>; break;
    case "deactivated":
      const endDateStr = new Date(startdate).toLocaleString('en-US', dateFormatOpts);
      statusElement = <><span className="has-text-danger">Closed </span>on {endDateStr} </>; break;
    default:
      statusElement = null; break;
  }

  return (
    <React.Fragment>
      <div className="tile is-child">
        <h1 className="title is-4 has-text-centered">Campaign Status: {statusElement}</h1>
      </div>

      <div className="tile is-parent">
        <div className="tile is-child">
          <div>
            <label className="label mb-5">Pre-launch Checklist</label>
            <div className="mb-5">
              <Checkbox checked={script && script.length > 0} description={(<strong>Create Script</strong>)}/>
              <Checkbox checked={prompt && prompt.length > 0} description={(<strong>Create Prompts</strong>)}/>
              <Checkbox checked={login && login.length > 0} description={(<strong>Create Agent Logins</strong>)}/>
              <Checkbox checked={listlength} description={(<strong>Create Recipients</strong>)}/>
              { (listlength === 1) &&
                <WarningCheckbox description={`Only ${listlength} recipient has been uploaded`}/>
              }
              { (1 < listlength && listlength < 10) &&
                <WarningCheckbox description={`Only ${listlength} recipients have been uploaded`}/>
              }
            </div>
          </div>
          
          <div className="buttons">
            {active === "notyet" && (
              <React.Fragment>
                <ConfirmActionModal
                  itemName={name}
                  actionName="launch campaign"
                  actionCallback={requestCampaignLaunch}
                  buttonClass={`button is-success ${!campaignReady.includes("launch") ? " is-outlined" : ""}`}
                  disabled={!campaignReady.includes("launch")}
                />
                <ConfirmActionModal
                  itemName={name}
                  actionName="Enter sandbox mode"
                  actionCallback={enterSandboxMode}
                  buttonClass={`button is-info ${!campaignReady.includes("sandbox") ? " is-outlined" : ""}`}
                  disabled={!campaignReady.includes("sandbox")}
                />
              </React.Fragment>
            )}
            {active === "sandbox" && (
              <React.Fragment>
                <ConfirmActionModal
                  itemName={name}
                  actionName="launch campaign"
                  actionCallback={requestCampaignLaunch}
                  buttonClass="button is-success is-outlined"
                  disabled={true}
                />
                <ConfirmActionModal
                  itemName={name}
                  actionName="Exit sandbox mode"
                  actionCallback={exitSandboxMode}
                  buttonClass="button is-info"
                />
              </React.Fragment>
            )}
            {active === "prelaunch" && (
              <button className="button is-success is-outlined" disabled>
                  ...Launch Approval Pending
              </button>
            )}
            </div>
        </div>

        <div className="tile is-child">
          <div className="buttons">
          <label className="label mb-5">Campaign Actions</label>
            {active !== "deactivated" && active !== "notyet" && active !== "sandbox" && (
              <ConfirmActionModal
                itemName={name}
                actionName="end campaign"
                actionCallback={deactivateCampaign}
                buttonClass="button is-danger is-fullwidth"
              />
            )}
            {(active === "active" || active === "paused") && (
              <PauseResumeCampaignModal
                name={name}
                campaignid={campaignid}
                status={active}
                subtwiliosid={subtwiliosid}
            />) }

            {active === "notyet" && (
              <RenameModal
              buttonClass="button is-light is-fullwidth"
                campaigns={campaigns}
                campaignid={campaignid}
                campaignName={name}
              />
            )}

            <CopyCampaignModal
              campaignName={name}
              organizations={vitals.organizationarray}
              buttonClass="button is-light is-fullwidth"
            />
            {active !== "notyet" && (
              <CampaignResultsModal 
                selectedCampaignId={campaignid}
                useExisting={false}
                fileExports={fileExports}
                buttonClass="button is-warning is-fullwidth"
              />
            )}

            {active !== "active" && (
              <ConfirmActionModal
                itemName={name}
                actionName="delete campaign"
                actionCallback={() => {
                  deleteCampaign(history);
                }}
                buttonClass="button is-danger is-outlined is-fullwidth"
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
