import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CampaignTools } from "./CampaignTools";

import {
  requestCampaignLaunch,
  closeCampaign,
} from "../redux/SelectedCampaign/actions";

function Tools({
  campaigns,
  selectedCampaignData,
  selectedCampaignId,
  saveCampaignName,
  deleteCampaign,
  closeCampaign,
  requestCampaignLaunch,
  enterSandboxMode,
  exitSandboxMode,
  history,
  auth,
  vitals
}) {
  return (
    <div className="tile is-ancestor">
      <div className="tile is-vertical is-parent">
        <CampaignTools
          campaigns={campaigns}
          deleteCampaign={deleteCampaign}
          requestCampaignLaunch={requestCampaignLaunch}
          deactivateCampaign={closeCampaign}
          saveCampaignName={saveCampaignName}
          enterSandboxMode={enterSandboxMode}
          exitSandboxMode={exitSandboxMode}
          history={history}
          jwt={auth.jwt}
          vitals={vitals}
          {...selectedCampaignData}
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestCampaignLaunch: () => requestCampaignLaunch(),
      closeCampaign: () => closeCampaign(),
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Tools);
